import React from "react";
import ChangePassword from "../../pages/auth/ChangePassword";
import { NavD } from "./NavD";
import { navlinks } from "../../components/components/data/travigodata";
import { Grid } from "@mui/material";

export const ChangePass = () => {
  return (
    <>
      {/* <NavD navlinks={navlinks} /> */}
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{
          minHeight: "100vh",
          marginTop: "180px",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <Grid item>
          <NavD navlinks={navlinks} />
        </Grid>
        <Grid item>
          <ChangePassword />
        </Grid>
      </Grid>
    </>
  );
};
