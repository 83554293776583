import { Avatar, Button, Drawer, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../images/images/logo11.png";
import menu from "../../images/images/menu.svg";
import PopupMenu from "../components/PopupMenu.jsx";
import profilePicture from "../../images/man.png";

import { useDispatch } from "react-redux";
import { unSetUserToken } from "../../features/authSlice";
import { getToken, removeToken } from "../../services/LocalStorageService";
import { useGetLoggedUserQuery } from "../../services/userAuthApi";
import { setUserInfo, unsetUserInfo } from "../../features/userSlice";

export const NavD = ({ navlinks }) => {
  const [popupState, setPopupState] = useState(false);
  const [navState, setNavState] = useState(false);
  const [openProfile, setOpenProfile] = useState(false); // Track profile drawer state
  const [dateOfBirth, setDateOfBirth] = useState(""); // State for date of birth
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    profilePicture: profilePicture, // Default profile picture
  });

  const onTriggerPopup = () => setPopupState(!popupState);
  const toggleProfileDrawer = () => setOpenProfile(!openProfile); // Toggle profile drawer state

  const onNavScroll = () => {
    if (window.scrollY > 180) {
      setNavState(true);
    } else {
      setNavState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onNavScroll);

    return () => {
      window.removeEventListener("scroll", onNavScroll);
    };
  }, []);

  const handleLogout = () => {
    dispatch(unsetUserInfo({ name: "", email: "", profilePicture: "" }));
    dispatch(unSetUserToken({ access_token: null }));
    removeToken();
    localStorage.removeItem("userData"); // Remove user data
    localStorage.removeItem("dateOfBirth"); // Remove date of birth
    navigate("/login");
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token } = getToken();
  const { data, isSuccess } = useGetLoggedUserQuery(access_token);

  useEffect(() => {
    if (data && isSuccess) {
      setUserData({
        email: data.email,
        name: data.name,
        profilePicture: data.profilePicture || profilePicture, // Use default profile picture if not available
      });
      // Store user data locally
      localStorage.setItem(
        "userData",
        JSON.stringify({
          email: data.email,
          name: data.name,
          profilePicture: data.profilePicture || profilePicture, // Use default profile picture if not available
        })
      );
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        setUserInfo({
          email: data.email,
          name: data.name,
          profilePicture: data.profilePicture || profilePicture, // Use default profile picture if not available
        })
      );
    }
  }, [data, isSuccess, dispatch]);

  // Load user data from local storage when component mounts
  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  // Load date of birth from local storage when component mounts
  useEffect(() => {
    const storedDateOfBirth = localStorage.getItem("dateOfBirth");
    if (storedDateOfBirth) {
      setDateOfBirth(storedDateOfBirth);
    }
  }, []);

  // Save date of birth to local storage when it changes
  useEffect(() => {
    localStorage.setItem("dateOfBirth", dateOfBirth);
  }, [dateOfBirth]);

  // Function to handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader(); // Create a file reader
      reader.onload = () => {
        const imageUrl = reader.result; // Get the data URL of the uploaded image
        setUserData((prevState) => ({
          ...prevState,
          profilePicture: imageUrl, // Update profile picture in state
        }));
        localStorage.setItem(
          "userData",
          JSON.stringify({
            // Update profile picture in local storage
            ...userData,
            profilePicture: imageUrl,
          })
        );
      };
      reader.readAsDataURL(file); // Read the file as data URL
    }
  };

  return (
    <>
      <header
        className={`
         nav-default ${navState && "nav-sticky"}
       `}
      >
        <nav className="flex items-center justify-between travigo-container">
          <NavLink to={`/`} className="flex items-center">
            <img src={logo} alt="logo/img" className="w-20 h-20 object-fill" />
          </NavLink>
          <ul className="flex items-center lg:hidden gap-7">
            {navlinks?.map((val, i) => (
              <li key={i}>
                <NavLink to={`/${val.id}`} className="text-lg text-slate-900">
                  {val.link}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="flex items-center lg:hidden">
            <li>
              <Avatar
                alt={userData.name}
                src={userData.profilePicture}
                onClick={toggleProfileDrawer}
              />
            </li>
          </ul>
          <ul className="hidden lg:flex items-center">
            <li>
              <button
                type="button"
                className="flex items-center justify-center transition-all duration-200 active:scale-90 cursor-pointer"
                onClick={onTriggerPopup}
              >
                <img
                  src={menu}
                  alt="menu/svg"
                  className="object-cover shadow-sm filter"
                />
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <PopupMenu navlinks={navlinks} popupState={popupState} />

      {/* Profile Drawer */}
      <Drawer anchor="right" open={openProfile} onClose={toggleProfileDrawer}>
        <div style={{ height: "90vh", width: "33vw" }}>
          <Grid container spacing={2} style={{ padding: 20 }}>
            <Grid item xs={12}>
              <img
                src={userData.profilePicture}
                alt="Profile"
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">
                Name:
                {userData.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Email:
                {userData.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Date of Birth:
                <input
                  type="date"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </Typography>
            </Grid>
            {/* Input field for uploading profile picture */}
            <Grid item xs={12}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate("/change-password")}
                sx={{ mt: 2 }}
              >
                Change Password
              </Typography>
            </Grid>
            <Button
              variant="contained"
              color="warning"
              size="large"
              onClick={handleLogout}
              sx={{ mt: 2 }}
            >
              Logout
            </Button>
          </Grid>
        </div>
      </Drawer>
    </>
  );
};
