import React from "react";
import { Nav } from "../components/Nav";
import { navlinks } from "../components/data/travigodata";
// import { Hero1 } from "../Hero1";
import { hero } from "../components/data/travigodata";
import { Hero2 } from "./Hero2";

export const Getstarted = () => {
  return (
    <>
      <Nav navlinks={navlinks} />
      <Hero2 hero={hero} />
    </>
  );
};
