// import { Grid } from "@mui/material";
// import { Nav} from '../components/components/Nav';
import { SearchBar } from "../Getstarted/SearchBar";
import { C_Hero_3 } from "../Contacti_file/C_Hero_3";
// import { Hero} from '../use/components/Hero';
// import { navlinks} from '../components/components/data/travigodata';
import { hero } from "../components/data/travigodata";
import ATripPlanner from "../Getstarted/ATripPlanner";
// import Memory from '../components/components/Memory';
// import { memory} from '../components/components/data/travigodata';
// import Explore from '../components/components/Explore';
// import { placesAPI} from '../components/components/data/travigodata';
// import Advertise from '../components/components/Advertise';
// import { brands} from '../components/components/data/travigodata';
// import Footer from '../components/components/Footer';
// import { footerAPI} from '../components/components/data/travigodata';
// import Newslatter from '../components/components/Newslatter';
// import Banner from '../components/components/Banner';
// import { bannerAPI} from '../components/components/data/travigodata';

const HomeD = () => {
  return (
    <>
      {/* <Nav navlinks={navlinks} /> */}
      <C_Hero_3 hero={hero} />
      {/* <SearchBar /> */}
      <ATripPlanner />
      {/* <Memory memory={memory} /> */}
      {/* <Explore title="Explore The Beauty of India" placesAPI={placesAPI} /> */}
      {/* <Advertise brands={brands} /> */}
      {/* <Banner bannerAPI={bannerAPI} /> */}
      {/* <Newslatter/> */}
      {/* <Footer  footerAPI={footerAPI} /> */}
    </>
  );
};

export default HomeD;
