import React from "react";
import "./AboutUsPage.css";
import { Nav } from "../components/Nav";
import { navlinks } from "../components/data/travigodata";
import { hero } from "../components/data/travigodata";
import { C_Hero_3 } from "../Contacti_file/C_Hero_3";

const AboutUsPage = () => {
  return (
    <>
      {" "}
      <Nav navlinks={navlinks} />
      <C_Hero_3 hero={hero} />
      <div className="about-us-container">
        {" "}
        {/* Apply container class */}
        <h1>About Us</h1>
        <p>
          Welcome to our website! We are a team of passionate individuals
          dedicated to providing the best service to our customers.
        </p>
        <p>
          Our mission is to [" democratize smart travel planning through
          intuitive AI tools, simplifying itinerary creation and enhancing the
          overall travel experience for all."].
        </p>
        <h2>AeroOxy</h2>
        <div className="team-members">
          <div className="team-member">
            <h3>Sujit Sutradhar</h3>
            {/* <p>CEO</p> */}
          </div>
          <div className="team-member">
            <h3>Aniket Yadav</h3>
            {/* <p>COO</p> */}
          </div>
          {/* Add more team members as needed */}
        </div>
        {/* <h2>Our History</h2>
        <p>
          Founded in [insert year], our company has grown from humble beginnings
          to become a leader in our industry.
        </p> */}
      </div>
    </>
  );
};

export default AboutUsPage;
