import React, { useState } from "react";
import attractionsData from "./attractionsData.json";
import "./ATrip.css";
import suggestions from "./suggestions"; // Import the array of city names
import hotelData from "./hotelData";
import foodData from "./foodData.json";

const generateHotelRecommendations = (destination) => {
  return hotelData[destination.toLowerCase()] || [];
};

const generateFoodRecommendations = (destination) => {
  return foodData[destination.toLowerCase()] || [];
};

const convertTimeSlot = (slot) => {
  if (slot === "Morning") return 1;
  else if (slot === "Afternoon") return 2;
  else if (slot === "Evening") return 3;
  else return 0;
};

const generateTravelPlan = (
  origin,
  travelWith,
  vacationType,
  budget,
  destination,
  numDays
) => {
  destination = destination.toLowerCase();
  const attractions = attractionsData.attractions.filter(
    (attraction) => attraction.City.toLowerCase() === destination
  );

  if (attractions.length === 0) {
    return "No attractions available for the specified destination.";
  }

  const attractionsShuffled = attractions.sort(
    (a, b) =>
      convertTimeSlot(a["Best Time to visit"]) -
      convertTimeSlot(b["Best Time to visit"])
  );
  const attractionsPerDay = Math.min(
    4,
    Math.floor(attractionsShuffled.length / numDays)
  );

  const travelPlan = {};

  for (let day = 1; day <= numDays; day++) {
    const startIdx = (day - 1) * attractionsPerDay;
    const endIdx = startIdx + attractionsPerDay;
    const selectedAttractions = attractionsShuffled.slice(startIdx, endIdx);

    travelPlan[`Day ${day}`] = selectedAttractions;
  }

  return { origin, travelWith, vacationType, budget, travelPlan };
};

const ATripPlanner = () => {
  const [origin, setOrigin] = useState("");
  const [travelWith, setTravelWith] = useState("");
  const [vacationType, setVacationType] = useState("");
  const [budget, setBudget] = useState("");
  const [destination, setDestination] = useState("");
  const [numDays, setNumDays] = useState(0);
  const [plan, setPlan] = useState(null);
  const [suggestedOrigins, setSuggestedOrigins] = useState([]);
  const [suggestedDestinations, setSuggestedDestinations] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hotelRecommendations, setHotelRecommendations] = useState([]);
  const [foodRecommendations, setFoodRecommendations] = useState([]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleOriginChange = (e) => {
    const value = e.target.value;
    setOrigin(value);
    if (value) {
      const filteredOrigins = suggestions.filter((city) =>
        city.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestedOrigins(filteredOrigins);
    } else {
      setSuggestedOrigins([]);
    }
  };

  const handleDestinationChange = (e) => {
    const value = e.target.value;
    setDestination(value);
    if (value) {
      const filteredDestinations = suggestions.filter((city) =>
        city.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestedDestinations(filteredDestinations);
    } else {
      setSuggestedDestinations([]);
    }
  };

  const handleOriginSelect = (city) => {
    setOrigin(city);
    setSuggestedOrigins([]);
  };

  const handleDestinationSelect = (city) => {
    setDestination(city);
    setSuggestedDestinations([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (parseInt(budget) < 5000) {
      setIsLoading(false);
      alert("Please increase your budget to at least 5000.");
      return;
    }

    const generatedPlan = generateTravelPlan(
      origin,
      travelWith,
      vacationType,
      budget,
      destination,
      numDays
    );

    const hotelRecommendations = generateHotelRecommendations(destination);
    const foodRecommendations = generateFoodRecommendations(destination);

    setTimeout(() => {
      setPlan(generatedPlan);
      setHotelRecommendations(hotelRecommendations);
      setFoodRecommendations(foodRecommendations);
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <label htmlFor="from">Origin:</label>
        <input
          type="text"
          value={origin}
          onChange={handleOriginChange}
          placeholder="Origin:"
          required
        />
        {suggestedOrigins.length > 0 && (
          <ul className="autocomplete-dropdown">
            {suggestedOrigins.map((city) => (
              <li key={city} onClick={() => handleOriginSelect(city)}>
                {city}
              </li>
            ))}
          </ul>
        )}
        <label htmlFor="to">Destination:</label>
        <input
          type="text"
          value={destination}
          onChange={handleDestinationChange}
          placeholder="Enter destination city"
          required
        />
        {suggestedDestinations.length > 0 && (
          <ul className="autocomplete-dropdown">
            {suggestedDestinations.map((city) => (
              <li key={city} onClick={() => handleDestinationSelect(city)}>
                {city}
              </li>
            ))}
          </ul>
        )}
        <input
          type="number"
          value={numDays}
          onChange={(e) => setNumDays(parseInt(e.target.value))}
          placeholder="Enter number of days"
          required
        />
        <label htmlFor="travelling_with">Travelling with:</label>
        <select
          id="travelling_with"
          name="travelling_with"
          value={travelWith}
          onChange={(e) => setTravelWith(e.target.value)}
          required
        >
          <option value="">Select travelling with</option>
          <option value="Individual">Individual</option>
          <option value="Friends">Friends</option>
          <option value="Family">Family</option>
        </select>

        <label htmlFor="vacationType">Vacation Type:</label>
        <select
          id="vacationType"
          name="vacationType"
          value={vacationType}
          onChange={(e) => setVacationType(e.target.value)}
          required
        >
          <option value="">Select vacation type</option>
          <option value="Adventure travel">Adventure travel</option>
          <option value="Relaxation">Relaxation</option>
          <option value="Cultural and Historical">
            Cultural and Historical
          </option>
          <option value="Romantic for Couples">Romantic for Couples</option>
          <option value="Solo Nomad">Solo Nomad</option>
          <option value="Family Vacationer">Family Vacationer</option>
          <option value="City">City</option>
        </select>

        <label htmlFor="budget">Budget:</label>
        <input
          type="range"
          id="budget"
          name="budget"
          min={1000}
          max={100000}
          step={100}
          value={budget}
          onChange={(e) => setBudget(e.target.value)}
          required
        />
        <span>₹ {budget}</span>

        <div className="dropdown">
          <button onClick={toggleDropdown}>Select Options</button>
          {showDropdown && (
            <div className={`dropdown-content`}>
              <label>
                <input
                  type="checkbox"
                  value="Option 1"
                  onChange={handleCheckboxChange}
                />{" "}
                Option 1
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Option 2"
                  onChange={handleCheckboxChange}
                />{" "}
                Option 2
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Option 3"
                  onChange={handleCheckboxChange}
                />{" "}
                Option 3
              </label>
            </div>
          )}
          <div className="selected-items">
            {selectedOptions.map((option, index) => (
              <div key={index}>{option}</div>
            ))}
          </div>
        </div>

        <button className="generate-plan-button" type="submit">
          Generate Plan
        </button>
      </form>
      {isLoading && <div className="loader">Loading...</div>}
      {plan && !isLoading && (
        <div>
          <div className="user-info-box">
            <h2>User Information:</h2>
            <p>
              <strong>Origin:</strong> {plan.origin}
            </p>
            <p>
              <strong>Destination:</strong> {destination}
            </p>
            <p>
              <strong>Travelling with:</strong> {plan.travelWith}
            </p>
            <p>
              <strong>Vacation Type:</strong> {plan.vacationType}
            </p>
            <p>
              <strong>Budget:</strong> {plan.budget}
            </p>
          </div>

          <div className="TravelPlanecss">
            <h2>Travel Plan:</h2>
            {typeof plan === "string" ? (
              <p>{plan}</p>
            ) : (
              Object.entries(plan.travelPlan).map(([day, attractions]) => (
                <div key={day}>
                  <h3>{day}</h3>

                  {attractions.map((attraction, index) => (
                    <div key={index} className="plan">
                      <li style={{ fontSize: "24px" }}>
                        <a
                          href={`https://en.wikipedia.org/wiki/${encodeURIComponent(
                            attraction.Name
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {attraction.Name}
                        </a>
                        {attraction.googleMapsLink && (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              attraction.Name
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            (Google Maps)
                          </a>
                        )}
                      </li>
                      <img src={attraction.imageSrc} alt={attraction.Name} />
                      <p>
                        Google Review Rating:{" "}
                        {attraction["Google review rating"]}
                      </p>
                      <p>
                        Entrance Fee in INR: ₹{" "}
                        {attraction["Entrance Fee in INR"]}
                      </p>
                      <p>Weekly Off: {attraction["Weekly Off"]}</p>
                      <p>Significance: {attraction["Significance"]}</p>
                      <p>DSLR Allowed: {attraction["DSLR Allowed"]}</p>
                      <p>
                        Best Time to visit: {attraction["Best Time to visit"]}
                      </p>
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>

          <div className="bestfood-container">
            <div className="bestfood">
              <h2>
                <i className="fas fa-utensils icon"></i> Best Food:
              </h2>
              {foodRecommendations.length > 0 ? (
                <div className="food-list">
                  {foodRecommendations.map((food, index) => (
                    <div key={index} className="food-item">
                      <img
                        src={food.image}
                        alt={food.name}
                        className="food-image"
                      />
                      <div className="food-details">
                        <h4>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                              food.name
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {food.name}
                          </a>
                        </h4>
                        <p>Cuisine: {food.cuisine}</p>
                        <p>Rating: {food.rating}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-recommendations">
                  No food recommendations available for this destination.
                </p>
              )}
            </div>
          </div>

          <div className="Hotel">
            <h2>Top Hotels:</h2>
            {hotelRecommendations.length > 0 ? (
              <div>
                <ul>
                  {hotelRecommendations.map((hotel, index) => (
                    <div key={index} className="hotelnamebox">
                      <h4>{hotel.name}</h4>
                      <p>Rating: {hotel.rating}</p>
                      <p>Price: {hotel.price}</p>
                      <a
                        href={hotel.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visit Website
                      </a>
                    </div>
                  ))}
                </ul>
              </div>
            ) : (
              <p>No hotel recommendations available for this destination.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ATripPlanner;
