const hotelData = {
    jaipur: [
        { name: "Radisson Blu Hotel, Jaipur", rating: 4.4, price: "5000", website: "https://www.makemytrip.com/hotels/hotel-details/?hotelId=201301221205489537&Campaign=20589932877&_uCurrency=INR&au=8452033970&checkin=04262024&checkout=04272024&city=CTJAI&cmp=googlehoteldfinder_DH_META_Paid_default_IN_localuniversal_201301221205489537&country=IN&gclid=CjwKCAjw26KxBhBDEiwAu6KXt2V-HyIrPbijg6Pv-I3ZZTTU6ENufgUPm3S4wqqiGX9LwtBsXjhvLhoCZBIQAvD_BwE&lat=26.84217&lng=75.79405&locusId=CTJAI&locusType=city&mtkeys=ab02e8d3-2fe5-4228-b2b8-bf993406b1dd&rank=1&roomCount=1&roomStayQualifier=2e0e&topHtlId=201301221205489537&totalGuestCount=2&viewType=PREMIUM" },
        { name: "The Lalit Jaipur", rating: 4.2, price: "4200", website: "https://www.makemytrip.com/hotels/hotel-details/?hotelId=201203301239342377&Campaign=20589932877&_uCurrency=INR&au=8452033970&checkin=04262024&checkout=04272024&city=CTJAI&cmp=googlehoteldfinder_DH_META_Paid_default_IN_localuniversal_201203301239342377&country=IN&gclid=CjwKCAjw26KxBhBDEiwAu6KXt0wpsSSA9XVDfTmCpwVGwVFHM8IFxfcTJze9UKHmwnYNXiVbPO4EARoCJzkQAvD_BwE&lat=26.84012&lng=75.8074&locusId=CTJAI&locusType=city&mtkeys=b40d3282-0758-454a-9cdf-24540efade9f&rank=1&roomCount=1&roomStayQualifier=2e0e&topHtlId=201203301239342377&totalGuestCount=2&viewType=PREMIUM" },
        { name: "ITC Rajputana, a Luxury Collection Hotel, Jaipur", rating: 4.7, price: "8000", website: "https://res.itchotels.com/?adult=2&arrive=2024-04-26&chain=26676&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61292897369862144&filter=META&hotel=30173&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-30173&utm_content=localuniversal_26-04-2024_1_30173_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "Royale Lalit", rating: 4.3, price: "2500", website: "https://www.makemytrip.com/hotels/hotel-listing/?topHtlId=201601151154311790&city=CTJAI&country=IN&checkin=04262024&checkout=04272024&roomStayQualifier=2e0e&totalGuestCount=2&roomCount=1&cmp=googlehoteldfinder_DH_META_Paid_default_IN_localuniversal_201601151154311790&_uCurrency=INR&Campaign=20613919640&locusId=CTJAI&locusType=city&mtkeys=1ac44732-07ca-4399-a67e-3db819dcdeb4&au=8452033970&gclid=CjwKCAjw26KxBhBDEiwAu6KXt1bEuht-2b5efIHYApdYPD_QMyGL2vCsQgKE_YUOW7XQH2e4tGweDBoC0pUQAvD_BwE" },
        { name: "Ramada by Wyndham Jaipur North", rating: 4, price: "3500", website: "https://www.goibibo.com/hotels/meta/google/4278754392716898526/6659325433993444125/%7B%22ci%22:%2220240426%22,%22co%22:%2220240427%22,%22r%22:%221-2_0%22,%22ibp%22:%22v15%22%7D/?hquery={%22ci%22:%2220240426%22,%22co%22:%2220240427%22,%22r%22:%221-2_0%22,%22qd%22:%2220240426-20240427-1-2_0%22,%22ibp%22:%22v15%22}&utm_source=meta&cmp=META|google|cpc_hpa|googlehoteldfinder|Hotel_Price_Ads_6659325433993444125|META&utm_medium=cpc_hpa&utm_campaign=Hotel_Price_Ads_19905358927_6659325433993444125&vendor=gds&p=4058.84&c=INR&bookingSource=commissions&adType=1&gclid=CjwKCAjw26KxBhBDEiwAu6KXt3Ysy_gtlFqNgqwlck0znRJ7Rd68XXfDIB6yMstVPnLeIGrMoY-WVxoCYwsQAvD_BwE" }
    ],
    delhi: [
        { name: "The Leela Ambience Convention Hotel Delhi", rating: 4.5, price: "7800", website: "https://reservations.theleela.com/?adult=2&arrive=2024-04-26&chain=23514&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61292943120543744&filter=META&hotel=3150&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-3150&utm_content=localuniversal_26-04-2024_1_3150_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "ITC Maurya, a Luxury Collection Hotel, New Delhi", rating: 4.7, price: "14000", website: "https://res.itchotels.com/?adult=2&arrive=2024-04-26&chain=26676&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61292964695519232&filter=META&hotel=30160&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-30160&utm_content=localuniversal_26-04-2024_1_30160_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "Taj Palace, New Delhi", rating: 4.7, price: "15000", website: "https://www.tajhotels.com/en-in/taj/taj-palace-new-delhi/rooms-and-suites/?from=26%2F04%2F2024&to=27%2F04%2F2024&rooms=1&adults=2&children=0&overrideSessionDates=true&tt_los=1&tt_checkin=2024-04-26&tt_checkout=2024-04-27&tt_user_device=desktop&tt_partner_id=201089&tt_adults=2&tt_children=0&Rate=T05%2CTUGR%2CT03&filter=META&wh_token=ada19e14-382a-4701-ac0d-456f77dfce9e-201089-pms&utm_source=Google_Meta&utm_medium=Meta_Search&utm_campaign=Meta_Search_Campaign&utm_content=Paid&utm_term=&tt_meta=Google&tt_ad_type=paid&tt_date_type=default&tt_user_country=IN&tt_user_device=desktop&tt_gha_campaign_id=21112752192&tt_gha_user_list_id=&gclid=CjwKCAjw26KxBhBDEiwAu6KXtwpXhM0v9G3x8fyDnhbrV2P8c_cNCYQSxBpuwGrYEqjNw8aZJ5AMKRoCybkQAvD_BwE&tt_claim_key=claim%3A854f484d89beae8ef57d55dc282904897032b84fc4647ef7ca6a8476163dacd2%3A46673ef65fbd467fb203357a89c65315%3A1713980638466" },
        { name: "Tivoli Grand Resort Hotel", rating: 4.1, price: "2600", website: "https://www.trivago.in/en-IN/srl?iSemThemeId=58890&search=100-1547139;dr-20240426-20240427;rc-1-2&adType=gha_paid&iDisp=28&sLanguageLocale=IN&sCurrencyCode=INR&iGhaThemeId=1&ghaDevice=desktop&ghaDateType=default&ghaPrice=2690.67&ghaSite=localuniversal&ghaGroup=11060&ghaArr=20240426&ghaDep=20240427&ghaABW=2&ghaList=&ghaCtx=CIsDEMgXGKW_ldr1i5myOiCY6-6tAioiCiAwMDAwMDAxNzEzOTgwNTkwMjA3ZWNiZWJhMDk0N2NiMTIgMDAwMDAwMTcxMzk4MDY3MThhMzMyMjA1ZTRhNWJiMGU4g7de&cipc=sem&ghaAdg=114763247197&ghaCam=2075485336&cip=9114000000&gad_source=0&gclid=CjwKCAjw26KxBhBDEiwAu6KXt29dZS9NGfb7-_OMvODpfHAs6GwatFaSY2S7USNcD-M5nmeJGf11PRoCEbMQAvD_BwE"},
        { name: "Lemon Tree Hotel Kaushambi", rating: 4, price: "4000", website: "https://www.agoda.com/search?campaignid=21176660066&searchdatetype=default&lt=2&numberofchildren=0&childages=&gsite=localuniversal&partnercurrency=INR&roomid=3467397&pricetax=749.36&pricetotal=3979.36&rateplan=26dee01f-6b7b-39dd-b72f-e3946a888146&usercountry=IN&currency=INR&userdevice=desktop&verif=false&audience_list=&mcid=332&booking_source=cpc&adtype=1&push_id=&gclid=CjwKCAjw26KxBhBDEiwAu6KXt_eOluV8U0k6WlAGSEwZWzhu-tOVJkiNpISBOUokbOZrD8CIg7U5KhoCpw8QAvD_BwE&los=1&adults=2&rooms=1&checkin=2024-04-26&checkout=2024-04-27&selectedproperty=97216&city=14552&cid=1833981&pslc=1&ds=O0TATVyhH2M7xCtK"}
    ],
    mumbai: [
        { name: "Radisson Blu Mumbai International Airport", rating: 4.5, price: "5000", website: "https://www.radissonhotels.com/en-us/hotels/radisson-blu-mumbai-airport?cid=a%3Ase+b%3Agmb+c%3Aapac+i%3Alocal+e%3Ardb+d%3Aind+h%3AINMHBOMAIR" },
        { name: "InterContinental Marine Drive-Mumbai, an IHG Hotel", rating: 4.7, price: "13000", website: "https://www.ihg.com/intercontinental/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99618783&icdv=99618783&glat=META_hpa_paid_IN_desktop_BOMHB_localuniversal_1_INR_2024-04-26_default_19749401936__FALSE_CSPNIGCOR&qSlH=BOMHB&qRms=1&qAdlt=2&qChld=0&qCiD=26&qCiMy=032024&qCoD=27&qCoMy=032024&qrtPt=15000.00&setPMCookies=true&qSlRc=CSPN&qRtP=IGCOR&qSHBrC=IC&qDest=135%20Marine%20Drive,%20Mumbai,%20IN&hmGUID=3007d651-5b0e-4654-abda-7e0f0c20278e&gclid=CjwKCAjw26KxBhBDEiwAu6KXt8GHT4XbpJW1mO0g_u7fLGeSKQfXY2752KDhu8PuOXhrGktywNmKoRoC6cwQAvD_BwE&cm_mmc=hpa_paid_IN_desktop_BOMHB_localuniversal_1_INR_2024-04-26_default_19749401936__FALSE_CSPNIGCOR&srb_u=1&qChAge=&qRmFltr=" },
        { name: "Trident Hotel Bandra Kurla", rating: 4.6, price: "9000", website: "https://www.agoda.com/search?site_id=1917614&campaignid=21176660567&searchdatetype=default&lt=2&numberofchildren=0&childages=&gsite=localuniversal&partnercurrency=INR&roomid=644541453&pricetax=1497.96&pricetotal=9820.00&rateplan=&usercountry=IN&currency=INR&userdevice=desktop&verif=false&audience_list=&mcid=28037&booking_source=cpc&adtype=1&push_id=CgYIgN2rsQYSBgiAgLGxBhgBIJqLCioMGAEqCCICCAEqAggEab20ff28-6ad7-4388-31fd-fdd646a96f0f_20240425_00&gclid=CjwKCAjw26KxBhBDEiwAu6KXt_KgcWkPluuhphYL9skMb4rOuFV9QaDjN_RO8syhQOIyfgg26kpnDBoCy44QAvD_BwE&los=1&adults=2&rooms=1&checkin=2024-04-26&checkout=2024-04-27&selectedproperty=165274&city=16850&pslc=1&ds=wDBmnMI5v7e7Xy4e" },
        { name: "The Royal Orchid", rating: 4.5, price: "3500", website: "https://www.expedia.co.in/Hotel-Search?selected=18612601&startDate=2024-04-26&endDate=2024-04-27&MDPCID=IN.META.HPA.HOTEL-CORESEARCH-desktop.HOTEL&adults=2&children=&MDPDTL=HTL.18612601.20240426.20240427.DDT.1.CID.21013998619.AUDID.7741848900.RRID.bex_in_desktop&mctc=10&ct=hotel&mpg=INR&mpf=3584.00&mpj=384.00&mpl=INR&exp_pg=google&langid=en&ad=2&tp=&utm_source=google&utm_medium=cpc&utm_term=18612601&utm_content=localuniversal&utm_campaign=HotelAds&rateplanid=384216852&mpm=24&mpn=315654460&mpo=EC&mpp=1&gclid=CjwKCAjw26KxBhBDEiwAu6KXt9m5_Yze2QcOx6G4lNAHDf9XKYdchICxdM-5F0VwdYPUNdr513intxoCvyMQAvD_BwE"},
        { name: "Hotel Metropolis Mumbai", rating: 4.6, price: "3500", website: "https://www.agoda.com/search?site_id=1917614&campaignid=21176660567&searchdatetype=default&lt=1&numberofchildren=0&childages=&gsite=localuniversal&partnercurrency=INR&roomid=279381175&pricetax=486.00&pricetotal=4536.00&rateplan=&usercountry=IN&currency=INR&userdevice=desktop&verif=false&audience_list=&mcid=332&booking_source=cpc&adtype=1&push_id=CgYIgN2rsQYSBgiAgLGxBhgBIOrShgkqDBgBKggiAggBKgIIBA%3D%3D84b93da9-eb90-53c6-3572-ad7b4c9a61e4_20240425_00&gclid=CjwKCAjw26KxBhBDEiwAu6KXtwhOUpT1oWfY5UFBou7zHt7a4MXLufd774ghVh9YU2UXEKOyjeZ8RBoCYuAQAvD_BwE&los=1&adults=2&rooms=1&checkin=2024-04-26&checkout=2024-04-27&selectedproperty=18983274&city=16850&pslc=1&ds=jHitzkZpa3UGcZLg"}
    
    ],
    bangalore: [
        { name: "Hyatt Centric Mg Road Bangalore", rating: 4.3, price: "6000", website: "https://www.trivago.in/en-IN/srl?iSemThemeId=58890&search=100-1238336;dr-20240426-20240427;rc-1-2&adType=gha_paid&iDisp=93&sLanguageLocale=IN&sCurrencyCode=INR&iGhaThemeId=1&ghaDevice=desktop&ghaDateType=default&ghaPrice=4288.00&ghaSite=localuniversal&ghaGroup=245148&ghaArr=20240426&ghaDep=20240427&ghaABW=2&ghaList=&ghaCtx=CMwUEMclGJ3kkpGez6bHaCCb6Ny6-_____8BKiIKIDAwMDAwMDE3MTM5Nzg4MTMwZjRiYmQxYTM1OGU0NmQxMiAwMDAwMDAxNzEzOTc4OTE5ZGE3NDMzMzYwODY1NWNhNjjAyks&cipc=sem&ghaAdg=114378099763&ghaCam=2075485336&cip=9114000000&gad_source=0&gclid=CjwKCAjw26KxBhBDEiwAu6KXt1JbccErJ79wrm-yWSvovkrbWKmGTz1IjHoNMxZuOmIghBDWo-O5zxoC11UQAvD_BwE" },
        { name: "The Leela Palace Bengaluru - Garden City's Only Modern Palace Hotel", rating: 4.6, price: "18000", website: "https://reservations.theleela.com/?adult=2&arrive=2024-04-26&chain=23514&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61293161223319552&filter=META&hotel=3153&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-3153&utm_content=localuniversal_26-04-2024_1_3153_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "The Oberoi, Bengaluru", rating: 4.7, price: "13000", website: "https://www.agoda.com/en-gb/the-oberoi-bengaluru/hotel/bangalore-in.html?finalPriceView=1&isShowMobileAppPrice=false&cid=1833981&numberOfBedrooms=&familyMode=false&adults=2&children=0&rooms=1&maxRooms=0&checkIn=2024-04-26&isCalendarCallout=false&childAges=&numberOfGuest=0&missingChildAges=false&travellerType=1&showReviewSubmissionEntry=false&currencyCode=INR&isFreeOccSearch=false&isCityHaveAsq=false&pushId=CgYIgN2rsQYSBgiAgLGxBhgBIMJFKgwYASoIIgIIASoCCAQ%3Ddb91a157-a97e-73a0-229d-a7d952587131_20240425_00&los=1&searchrequestid=6a52caae-8cd0-42b5-9be9-fdd9f39c7910&ds=dO7Ab5UHBvR9SjVu" },
    ],
    hyderabad: [
        { name: "ITC Kohenur, a Luxury Collection Hotel, Hyderabad", rating: 4.7, price: "10000", website: "https://res.itchotels.com/?adult=2&arrive=2024-04-26&chain=26676&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61293254660591616&filter=META&hotel=30161&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-30161&utm_content=localuniversal_26-04-2024_1_30161_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "Le Meridien Hyderabad", rating: 4.4, price: "10000", website: "https://res.itchotels.com/?adult=2&arrive=2024-04-26&chain=26676&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61293254660591616&filter=META&hotel=30161&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-30161&utm_content=localuniversal_26-04-2024_1_30161_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "Radisson Hyderabad Hitec City", rating: 4.2, price: "7000", website: "https://www.radissonhotels.com/en-us/booking/room-display?hotelCode=INAPHYDHIT&facilitatorId=GOOGLEHPAREZIDOR&checkInDate=2024-04-26&checkOutDate=2024-04-27&adults%5B%5D=2&children%5B%5D=0&searchType=lowest&currencyCode=INR&cid=a%3Amt+b%3Agha+c%3Aapac+d%3Aind+e%3Arad+g%3Aho+h%3AINDRDHC+i%3Agha_LOC+r%3Aglc&traffic_source=derbysoft&dsclid=61293294456147968&gclid=CJzon_G324UDFYvbhAAdP0gMCg&gclsrc=ds" },
        { name: "Deccan Serai Hotel, HITEC CITY, HYDERABAD", rating: 4.2, price: "4000", website: "https://www.booking.com/searchresults.en-gb.html?aid=1288252&label=metagha-link-LUIN-hotel-1837283_dev-desktop_los-1_bw-1_dow-Friday_defdate-1_room-0_gstadt-2_rateid-dg_aud-7177173165_gacid-6642513999_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240426_ppt-G_lp-2356_r-529967944307041133&utm_medium=localuniversal&hca=m&no_rooms=1&show_room=183728302_353060528_2_34_0_121524&utm_content=dev-desktop_los-1_bw-1_dow-Friday_defdate-1_room-0_gstadt-2_rateid-dg_aud-7177173165_gacid-6642513999_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240426_ppt-G&utm_campaign=IN&edgtid=mxzp_1HjSYCGUvbV1keyUg&utm_term=hotel-1837283&gclid=CjwKCAjw26KxBhBDEiwAu6KXt5zOeZEmhhuuKZEzuuujuLEy2i_kiu5o5sF0mNY4hJJ9OfvOK_JdXRoCx5MQAvD_BwE&ext_price_total=4658.30&efpc=QVmyCkZYswoT&utm_source=metagha&ts=1713980896&highlighted_hotels=1837283&checkin=2024-04-26&redirected=1&city=-2097701&hlrd=with_dates&group_adults=2&source=hotel&group_children=0&checkout=2024-04-27&keep_landing=1&sid=e71570b37425f3eed57de8cc730680e6"},
        { name: "Holiday Inn Express Hyderabad Hitec City, an IHG Hotel", rating: 4.2, price: "4000", website: "https://www.ihg.com/holidayinnexpress/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99618783&icdv=99618783&glat=META_hpa_paid_IN_desktop_HYDTC_localuniversal_1_INR_2024-04-26_default_19749401936__FALSE_CSPNIGCOR&qSlH=HYDTC&qRms=1&qAdlt=2&qChld=0&qCiD=26&qCiMy=032024&qCoD=27&qCoMy=032024&qrtPt=3999.00&setPMCookies=true&qSlRc=CSPN&qRtP=IGCOR&qSHBrC=EX&qDest=Survey%20No.%2035,%20Hyderabad,%20IN&hmGUID=3007d651-5b0e-4654-abda-7e0f0c20278e&gclid=CjwKCAjw26KxBhBDEiwAu6KXt2hVIPODxF-4CzX-aig3W25uUkYwRNBwk-aimM_pVVZpUNnpFxmpAhoC3t4QAvD_BwE&cm_mmc=hpa_paid_IN_desktop_HYDTC_localuniversal_1_INR_2024-04-26_default_19749401936__FALSE_CSPNIGCOR&srb_u=1&qChAge="}
    ],
    kolkata: [
        { name: "ITC Royal Bengal, a Luxury Collection Hotel, Kolkata", rating: 4.8, price: "12000", website: "https://res.itchotels.com/?adult=2&arrive=2024-04-26&chain=26676&child=0&config=meta&currency=INR&depart=2024-04-27&dsclid=61293373216387072&filter=META&hotel=30167&level=hotel&locale=en-US&rooms=1&src=goog&utm_campaign=google-hpa-30167&utm_content=localuniversal_26-04-2024_1_30167_IN_desktop_unknown_21068667034_unknown_standard&utm_medium=channel_connect&utm_source=google-hpa" },
        { name: "The Oberoi Grand Kolkata", rating: 4.7, price: "14000", website: "https://www.oberoihotels.com/hotels-in-kolkata/?utm_source=google&utm_medium=cpc&utm_campaign=Brand-S_E&param1=_sc_campaign_8070800365_kwd-469996986040__511254607276_g_c__aud-898350970197:kwd-1866758287_335151511012&param2=c&utm_term=oberoi%20grand%20kolkata&param3=8070800365&gclid=CjwKCAjw26KxBhBDEiwAu6KXt-aU4cqbcNhbClDBO1a1zGf_oO4G1GXyW3F6hLdK8pFYQyVtJNqRqxoC3qQQAvD_BwE" },
        { name: "Taj Bengal", rating: 4.7, price: "15000", website: "https://www.tajhotels.com/en-in/taj/taj-bengal-kolkata/rooms-and-suites/?from=26%2F04%2F2024&to=27%2F04%2F2024&rooms=1&adults=2&children=0&overrideSessionDates=true&tt_los=1&tt_checkin=2024-04-26&tt_checkout=2024-04-27&tt_user_device=desktop&tt_partner_id=201089&tt_adults=2&tt_children=0&Rate=T05%2CTUGR%2CT03&filter=META&wh_token=ada19e14-382a-4701-ac0d-456f77dfce9e-201089-pms&utm_source=Google_Meta&utm_medium=Meta_Search&utm_campaign=Meta_Search_Campaign&utm_content=Paid&utm_term=&tt_meta=Google&tt_ad_type=paid&tt_date_type=default&tt_user_country=IN&tt_user_device=desktop&tt_gha_campaign_id=21112752192&tt_gha_user_list_id=&gclid=CjwKCAjw26KxBhBDEiwAu6KXtyLnVY45-L7ebJIlGPl_f9qRcyc3ILslXXtSmGQxZzYjTz1UKxOUchoCR_AQAvD_BwE&tt_claim_key=claim%3A854f484d89beae8ef57d55dc282904897032b84fc4647ef7ca6a8476163dacd2%3A46673ef65fbd467fb203357a89c65315%3A1713980638466" },
        { name: "Aster Guest House Kolkata", rating: 4, price: "2600", website: "https://www.makemytrip.com/hotels/hotel-listing/?topHtlId=201911071843266586&city=CTCCU&country=IN&checkin=04262024&checkout=04272024&roomStayQualifier=2e0e&totalGuestCount=2&roomCount=1&cmp=googlehoteldfinder_DH_META_Paid_default_IN_localuniversal_201911071843266586&_uCurrency=INR&Campaign=20803522043&locusId=CTCCU&locusType=city&mtkeys=021dcf39-1ef7-4a9f-97f2-1dd57d375c8b&au=8452033970&gclid=CjwKCAjw26KxBhBDEiwAu6KXt5JLw5FgWgcrYYp1Kr-CNH71OXAnF_u_NcXr43bPk84bHqyXx1FhNxoCCQ0QAvD_BwE"},
        { name: "Calcutta Bungalow| Heritage Rooms", rating: 4.9, price: "5600", website: "https://www.makemytrip.com/hotels/hotel-listing/?topHtlId=201807101631237215&city=CTCCU&country=IN&homestay=true&checkin=04262024&checkout=04272024&roomStayQualifier=2e0e&cmp=googlehoteldfinder_DH-AA_IN_localuniversal_201807101631237215__AA&totalGuestCount=2&roomCount=1&type=CTY&_uCurrency=INR&Campaign=&locusType=city&locusId=CTCCU&mtkeys=093a285f-fb2b-463f-919e-3db05777cf9d"}
    ],
    guwahati: [
        { name: "Radisson Blu Hotel, Guwahati", rating: 4.6, price: "8000", website: "https://www.booking.com/searchresults.en-gb.html?aid=1288252&label=metagha-link-LUIN-hotel-1170186_dev-desktop_los-1_bw-2_dow-Friday_defdate-1_room-0_gstadt-2_rateid-public_aud-7177173165_gacid-6642513999_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240426_ppt-_lp-2356_r-14118783545373374945&utm_medium=localuniversal&hca=m&no_rooms=1&show_room=117018602_253816210_2_42_0&utm_content=dev-desktop_los-1_bw-2_dow-Friday_defdate-1_room-0_gstadt-2_rateid-public_aud-7177173165_gacid-6642513999_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240426_ppt-&utm_campaign=IN&edgtid=rryVGWuYSGKt1UJtZc1nGQ&utm_term=hotel-1170186&gclid=CjwKCAjw26KxBhBDEiwAu6KXtx8W17hv1gThYN1BO4WyqRKqNsoQ1YH7B0_Ukwunjk7AtPVyDrMN-RoCarAQAvD_BwE&ext_price_total=9735.00&efpc=glCwCpc1sAoT&utm_source=metagha&ts=1713980242&highlighted_hotels=1170186&checkin=2024-04-26&redirected=1&city=900050674&hlrd=with_dates&group_adults=2&source=hotel&group_children=0&checkout=2024-04-27&keep_landing=1&sid=e71570b37425f3eed57de8cc730680e6" },
        { name: "Novotel Guwahati GS Road", rating: 4.6, price: "9000", website: "https://all.accor.com/ssr/app/accor/rates/9581/index.en.shtml?dateIn=2024-04-26&nights=1&compositions=2&stayplus=false&snu=false&accessibleRooms=false&hideWDR=false&productCode=null&hideHotelDetails=true&gclid=CjwKCAjw26KxBhBDEiwAu6KXt3rm5B7aSTVnofjjzSHwQFXohUYKw1OzCWhE-k5alYUbFui2UX4laRoCu3oQAvD_BwE&utm_campaign=9581-IN-cpc-desktop-default-0--localuniversal-20453789504-0-0-1&utm_medium=partenariats&hmGUID=3007d651-5b0e-4654-abda-7e0f0c20278e&utm_source=Google%20Hotel%20Ads" },
        { name: "Vivanta Guwahati", rating: 4.4, price: "8000", website: "https://www.vivantahotels.com/en-in/vivanta-guwahati/rooms-and-suites/?from=26%2F04%2F2024&to=27%2F04%2F2024&rooms=1&adults=2&children=0&overrideSessionDates=true&tt_los=1&tt_checkin=2024-04-26&tt_checkout=2024-04-27&tt_user_device=desktop&tt_partner_id=201125&tt_adults=2&tt_children=0&Rate=R04%2CT05%2CTUGR%2CT03&filter=META&wh_token=8043e350-b612-4253-bad4-b179d808c245-201125-pms&utm_source=Google_Meta&utm_medium=Meta_Search&utm_campaign=Meta_Search_Campaign&utm_content=Paid&utm_term=&tt_meta=Google&tt_ad_type=paid&tt_date_type=default&tt_user_country=IN&tt_user_device=desktop&tt_gha_campaign_id=21122829802&tt_gha_user_list_id=&gclid=CjwKCAjw26KxBhBDEiwAu6KXtwq5-HA9NxXFP9h2q6pxnmSX0VDA0hWai9-MyiQIOvEjrKodBw35ZBoCaGAQAvD_BwE&tt_claim_key=claim%3A854f484d89beae8ef57d55dc282904897032b84fc4647ef7ca6a8476163dacd2%3A42ca84eb06a05f27e60e9d5893e777d9d23ed737%3A1713982556839" },
        { name: "Hotel Rajmahal", rating: 4.1, price: "8000", website: "https://www.vivantahotels.com/en-in/vivanta-guwahati/rooms-and-suites/?from=26%2F04%2F2024&to=27%2F04%2F2024&rooms=1&adults=2&children=0&overrideSessionDates=true&tt_los=1&tt_checkin=2024-04-26&tt_checkout=2024-04-27&tt_user_device=desktop&tt_partner_id=201125&tt_adults=2&tt_children=0&Rate=R04%2CT05%2CTUGR%2CT03&filter=META&wh_token=8043e350-b612-4253-bad4-b179d808c245-201125-pms&utm_source=Google_Meta&utm_medium=Meta_Search&utm_campaign=Meta_Search_Campaign&utm_content=Paid&utm_term=&tt_meta=Google&tt_ad_type=paid&tt_date_type=default&tt_user_country=IN&tt_user_device=desktop&tt_gha_campaign_id=21122829802&tt_gha_user_list_id=&gclid=CjwKCAjw26KxBhBDEiwAu6KXtwq5-HA9NxXFP9h2q6pxnmSX0VDA0hWai9-MyiQIOvEjrKodBw35ZBoCaGAQAvD_BwE&tt_claim_key=claim%3A854f484d89beae8ef57d55dc282904897032b84fc4647ef7ca6a8476163dacd2%3A42ca84eb06a05f27e60e9d5893e777d9d23ed737%3A1713982556839" },
        { name: "Nian Retreat", rating: 4, price: "3000", website: "https://www.bluepillow.in/checkout/654cb2063011da1e8b792f5f?begin=2024-04-26&end=2024-04-27&currency=INR&language=en&utm_campaign=vr&block_id=-b,T1N22bz9nkQJHXg_xE5yuEg73iLjXTsqldYvpzbmr5Fve3f7LwNm_JD4vJS6pSY6,-bkng-House&country=IN"},
        { name: "HOTEL THE KALYANIZ", rating: 4.1, price: "3500", website: "https://www.bluepillow.in/checkout/61ef0b57113b0ba9fb94b8db?begin=2024-04-26&end=2024-04-27&currency=INR&language=en&utm_campaign=vr&block_id=-b,NtZSd-hvxdk6Tz8TkY1iOGBqvfZkp-FEo1HXXWMSpQJBQaB9Qxij_22wZqahdZJT,-bkng-House&country=IN"},
        { name: "LeafyInn 3BHK Apartment - VIP Road, Sixmile", rating: 4.1, price: "3500", website: "https://www.bluepillow.in/checkout/654cafec3011da1e8b786a35?begin=2024-04-26&end=2024-04-27&currency=INR&language=en&utm_campaign=vr&block_id=-b,waodV_Ix_9loWPI8M5BDzSJ-xkwzsUBw90CsvOMd8JAHjexMCbxn9UeXH7dLejDQ,-bkng-House&country=IN"}
    ]
    
};

module.exports = hotelData;
