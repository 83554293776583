import React, { useState } from "react";
import "./FlightSearch.css";
import { Nav } from "../components/Nav";
import { navlinks } from "../components/data/travigodata";
import axios from "axios"; // Import Axios

const Flight = () => {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [passengers, setPassengers] = useState(1);
  const [flights, setFlights] = useState([]);

  const handleSearch = async () => {
    const options = {
      method: "GET",
      url: `https://aerodatabox.p.rapidapi.com/flights/searchBy/${origin}`, // Update with dynamic origin
      params: {
        withAircraftImage: "false",
        withLocation: "false",
      },
      headers: {
        "X-RapidAPI-Key": "eb36e3ea59mshef6cab41addeeffp133240jsn327262d4149a", // Replace with your actual API key
        "X-RapidAPI-Host": "aerodatabox.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      setFlights(response.data); // Assuming API returns an array of flight objects
    } catch (error) {
      console.error("Error fetching flights:", error);
    }
  };

  return (
    <>
      <Nav navlinks={navlinks} />
      <div className="flight-search-container">
        <h1>Flight Search</h1>
        <div className="search-form">
          <input
            type="text"
            placeholder="Origin"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          />
          <input
            type="text"
            placeholder="Destination"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          />
          <input
            type="date"
            placeholder="Departure Date"
            value={departureDate}
            onChange={(e) => setDepartureDate(e.target.value)}
          />
          <input
            type="date"
            placeholder="Return Date"
            value={returnDate}
            onChange={(e) => setReturnDate(e.target.value)}
          />
          <input
            type="number"
            placeholder="Passengers"
            value={passengers}
            onChange={(e) => setPassengers(parseInt(e.target.value))}
          />
          <button onClick={handleSearch}>Search</button>
        </div>
        <div className="flight-results">
          {/* Display flight results here */}
          {flights.map((flight, index) => (
            <div key={index}>
              <p>Flight {index + 1}</p>
              <p>Price: {flight.price}</p>
              {/* Add more flight details as needed */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Flight;
