import React from "react";
import Goap from "../../images/images/Explore/Goa.jpeg";

export const Goa = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",

    margin: "auto",
  };

  const imageStyle = {
    maxWidth: "100%",
    borderRadius: "5px",
    marginBottom: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    display: "inline-block",
  };

  const handleRedirect = () => {
    // Redirect logic goes here
    // Example: window.location.href = 'https://example.com';
  };

  return (
    <div style={containerStyle}>
      <img
        src={Goap} // Replace with your image URL
        alt="Himachal Pradesh"
        style={imageStyle}
      />
      <p>
        The state of Goa, in India, is famous for its beaches and places of
        worship. Tourism is its primary industry, and is generally focused on
        the coastal areas of Goa, with decreased tourist activity inland.
      </p>
      <a
        href="https://goatourism.gov.in/"
        style={linkStyle}
        onClick={handleRedirect}
      >
        Explore Goa
      </a>
    </div>
  );
};
