import React from "react";
import Ladakhp from "../../images/images/Explore/Ladakh.jpg";

export const Ladakh = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",

    margin: "auto",
  };

  const imageStyle = {
    maxWidth: "100%",
    borderRadius: "5px",
    marginBottom: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    display: "inline-block",
  };

  const handleRedirect = () => {
    // Redirect logic goes here
    // Example: window.location.href = 'https://example.com';
  };

  return (
    <div style={containerStyle}>
      <img
        src={Ladakhp} // Replace with your image URL
        alt="Himachal Pradesh"
        style={imageStyle}
      />
      <p>
        Ladakh tourism is famous for its remote culture and monastic traditions,
        alpine landscape and natural beauty, and most of all, its friendly
        people.
      </p>
      <a
        href="https://www.lehladakhtourism.com/"
        style={linkStyle}
        onClick={handleRedirect}
      >
        Explore Ladakh
      </a>
    </div>
  );
};
