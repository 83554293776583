import React from "react";
import Rajasthanp from "../../images/images/Explore/Sand_Dunes.jpg";

export const Rajasthan = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",

    margin: "auto",
  };

  const imageStyle = {
    maxWidth: "100%",
    borderRadius: "5px",
    marginBottom: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    display: "inline-block",
  };

  const handleRedirect = () => {
    // Redirect logic goes here
    // Example: window.location.href = 'https://example.com';
  };

  return (
    <div style={containerStyle}>
      <img
        src={Rajasthanp} // Replace with your image URL
        alt="Himachal Pradesh"
        style={imageStyle}
      />
      <p>
        Rajasthan 'Land of Kings' or 'Land of Kingdom' is India's largest state
        by area. The state is located in the northwest part of the country and
        is a home of cultural diversity. Its features include the ruins of Indus
        Valley Civilization, Temples, Forts and Fortresses in almost every city.
      </p>
      <a
        href="https://tourist.rajasthan.gov.in/#/"
        style={linkStyle}
        onClick={handleRedirect}
      >
        Explore Rajasthan
      </a>
    </div>
  );
};
