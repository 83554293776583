import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginReg from "./pages/auth/LoginReg";
import ResetPassword from "./pages/auth/ResetPassword";
import SendPasswordResetEmail from "./pages/auth/SendPasswordResetEmail";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import { useSelector } from "react-redux";
import { Getstarted } from "./components/Getstarted/Getstarted";
import {ChangePass} from "./components/Dashboard/ChangePass";
import {Explore_nav} from "./components/location/Explore/Explore_nav"

//location
import  { Himachal_Pradesh } from "./components/location/Himachal_Pradesh";
import {Ladakh} from "./components/location/Ladakh"
import {Rajasthan} from "./components/location/Rajasthan"
import {Goa} from "./components/location/Goa"
import {Kashmir} from "./components/location/Kashmir"
import Flight from "./components/flight/Flight";
import { Assam } from "./components/location/Assam";
import { Meghalaya } from "./components/location/Meghalaya";
import AboutUsPage from "./components/components/Aboutus";





function App() {
  const { access_token } = useSelector(state => state.auth)
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Getstarted" element={<Getstarted />} /> 
            <Route path="home" element={<Home />}/>
            <Route path="pricing" element={<Flight/>}/>
            <Route path="about" element={<AboutUsPage/>}/>


            <Route path="contact" element={<Contact />} />
            <Route path="explore" element={<Explore_nav />} />
            <Route path="/place/Himachal_Pradesh" element={<Himachal_Pradesh />} />
            <Route path="/place/Ladakh" element={<Ladakh />} />
            <Route path="/place/Rajasthan" element={<Rajasthan />} />
            <Route path="/place/Goa" element={<Goa />} />
            <Route path="/place/Kashmir" element={<Kashmir />} />
            <Route path="/place/Assam" element={<Assam />} />
            <Route path="/place/Meghalaya" element={<Meghalaya />} />

            
            <Route path="change-password" element={<ChangePass/>} />
            <Route path="login" element={!access_token ? <LoginReg /> : <Navigate to="/dashboard" />} />
            <Route path="sendpasswordresetemail" element={<SendPasswordResetEmail />} />
            <Route path="api/user/reset/:id/:token" element={<ResetPassword />} />
          </Route>
          <Route path="/dashboard" element={access_token ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="*" element={<h1>Error 404 Page not found !!</h1>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
