import { Grid } from "@mui/material";
import { Nav} from '../components/components/Nav';
import { navlinks} from '../components/components/data/travigodata';
import { hero} from '../components/components/data/travigodata';
import { C_Hero_3 } from "../components/Contacti_file/C_Hero_3";
import "../components/Contacti_file/Contact.css";
// import C_HERO_3 from '';

const Contact = () => {
  return <>
  <Nav navlinks={navlinks} />
  <C_Hero_3 hero={hero} />
  <Grid container justifyContent="center">
        <Grid item xs={5}>
          {/* <h1>Contact Page</h1> */}
          <hr />
          <div className="contact-section">
            <h2>Get in Touch</h2>
            <p>
              Have a question or want to get in touch? Fill out the form below
              to send us a message and we will get back to you as soon as
              possible.
            </p>
            <form>
              <input type="text" placeholder="Your Name" />
              <input type="email" placeholder="Your Email" />
              <textarea
                rows="5"
                placeholder="Your Message"
              ></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </Grid>
      </Grid>
  </>;
};

export default Contact;
