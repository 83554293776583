import {  CssBaseline, Grid,  } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { unSetUserToken } from '../features/authSlice';
// import { getToken, removeToken } from '../services/LocalStorageService';
// import ChangePassword from './auth/ChangePassword';
// import { useGetLoggedUserQuery } from '../services/userAuthApi';
// import { useEffect, useState } from 'react';
// import { setUserInfo, unsetUserInfo } from '../features/userSlice';
import {NavD} from '../components/Dashboard/NavD'
import { navlinks} from '../components/components/data/travigodata';
import HomeD from '../components/Dashboard/HomeD';





const Dashboard = () => {
  // const handleLogout = () => {
  //   dispatch(unsetUserInfo({ name: "", email: "" }))
  //   dispatch(unSetUserToken({ access_token: null }))
  //   removeToken()
  //   navigate('/login')
  // }
  // const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const { access_token } = getToken()
  // const { data, isSuccess } = useGetLoggedUserQuery(access_token)

  // const [userData, setUserData] = useState({
  //   email: "",
  //   name: ""
  // })

  // Store User Data in Local State
  // useEffect(() => {
  //   if (data && isSuccess) {
  //     setUserData({
  //       email: data.email,
  //       name: data.name,
  //     })
  //   }
  // }, [data, isSuccess])

  // // Store User Data in Redux Store
  // useEffect(() => {
  //   if (data && isSuccess) {
  //     dispatch(setUserInfo({
  //       email: data.email,
  //       name: data.name
  //     }))
  //   }
  // }, [data, isSuccess, dispatch])

  return <>
  {/* <NavD/> */}
  <NavD navlinks={navlinks} />
  <HomeD/>
  
  
  <CssBaseline />
<Grid container sx={{ marginTop: '130px' }}>
  {/* <Grid item sm={3} sx={{ backgroundColor: 'gray', p: 5, color: 'white' }}>
    <h1>Dashboard</h1>
    <Typography variant='h5'>Email: {userData.email}</Typography>
    <Typography variant='h6'>Name: {userData.name}</Typography>
    <Button variant='contained' color='warning' size='large' onClick={handleLogout} sx={{ mt: 8 }}>Logout</Button>
  </Grid> */}
  <Grid item sm={8}>
    {/* <ChangePassword /> */}
  </Grid>
</Grid>

  </>;
};

export default Dashboard;