// import { Grid } from "@mui/material";
import { Nav} from '../components/components/Nav';
import { Hero1 } from '../components/components/Hero1';
// import { Hero} from '../use/components/Hero';
import { navlinks} from '../components/components/data/travigodata';
import { hero} from '../components/components/data/travigodata';
import Memory from '../components/components/Memory';
import { memory} from '../components/components/data/travigodata';
import Explore from '../components/components/Explore';
import { placesAPI} from '../components/components/data/travigodata';
import Advertise from '../components/components/Advertise';
import { brands} from '../components/components/data/travigodata';
import Footer from '../components/components/Footer';
import { footerAPI} from '../components/components/data/travigodata';
import Newslatter from '../components/components/Newslatter';
import Banner from '../components/components/Banner';
import { bannerAPI} from '../components/components/data/travigodata';
// import { Sledding } from '@mui/icons-material';

const Home = () => {
  return <>

  <Nav navlinks={navlinks} />
  <Hero1 hero={hero} />
  {/* <Sledding/> */}
  <Memory memory={memory} />
  <Explore title="Explore The Beauty of India" placesAPI={placesAPI} />
  <Advertise brands={brands} />
  <Banner bannerAPI={bannerAPI} />
  <Newslatter/>
  <Footer  footerAPI={footerAPI} />

  
  </>;
};

export default Home;
