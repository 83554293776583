import React from "react";
import Kashmirp from "../../images/images/Explore/Srinagar.jpeg";

export const Kashmir = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",

    margin: "auto",
  };

  const imageStyle = {
    maxWidth: "100%",
    borderRadius: "5px",
    marginBottom: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    display: "inline-block",
  };

  const handleRedirect = () => {
    // Redirect logic goes here
    // Example: window.location.href = 'https://example.com';
  };

  return (
    <div style={containerStyle}>
      <img
        src={Kashmirp} // Replace with your image URL
        alt="Himachal Pradesh"
        style={imageStyle}
      />
      <p>
        Kashmiris are well adept at knitting and making Pashmina shawls, silk
        carpets, rugs, kurtas, and pottery. Saffron, too, is grown in Kashmir.
        Srinagar is known for its silver-work, papier-mâché, wood-carving, and
        the weaving of silk.
      </p>
      <a
        href="https://www.kashmir-tourism.org/"
        style={linkStyle}
        onClick={handleRedirect}
      >
        Explore Kashmiris
      </a>
    </div>
  );
};
