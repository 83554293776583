import React from "react";
import Goap from "../../images/images/Explore/Cherrapunji.jpeg";

export const Meghalaya = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",

    margin: "auto",
  };

  const imageStyle = {
    maxWidth: "100%",
    borderRadius: "5px",
    marginBottom: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    display: "inline-block",
  };

  const handleRedirect = () => {
    // Redirect logic goes here
    // Example: window.location.href = 'https://example.com';
  };

  return (
    <div style={containerStyle}>
      <img
        src={Goap} // Replace with your image URL
        alt="Himachal Pradesh"
        style={imageStyle}
      />
      <p>
        Meghalaya is a state in northeast India. Meghalaya was formed on 21
        January 1972 by carving out two districts from the state of Assam: the
        United Khasi Hills and Jaintia Hills and the Garo Hills. The estimated
        population of Meghalaya
      </p>
      <a
        href="https://www.meghalayatourism.in/"
        style={linkStyle}
        onClick={handleRedirect}
      >
        Explore Meghalaya
      </a>
    </div>
  );
};
