import dashboard from "./road-trip-s.png";
import memoryimg from "../../../images/images/memory.png";

import brand1 from "../../../images/logo_brand/skyscanner.png";
import brand2 from "../../../images/logo_brand/Agoda-New.png";
import brand3 from "../../../images/logo_brand/Holidify Logo Vector.png";
import brand4 from "../../../images/logo_brand/Momondo-Logo.wine.png";
import brand5 from "../../../images/logo_brand/redbus.png";

import place1 from "../../../images/images/Explore/Rohtang_Pass.jpg";
import place2 from "../../../images/images/Explore/Ladakh.jpg";
import place3 from "../../../images/images/Explore/Sand_Dunes.jpg";
import place4 from "../../../images/images/Explore/Goa.jpeg";
import place5 from "../../../images/images/Explore/Srinagar.jpeg";
import place6 from "../../../images/images/Explore/Shimla.jpeg";
import place7 from "../../../images/images/Explore/Cherrapunji.jpeg";
import place8 from "../../../images/images/Explore/Ketti_Valley.jpeg";
import place9 from "../../../images/images/Explore/Valley_of_Flowers.jpeg";
import place10 from "/Volumes/DataBase/final/aerooxy_trip/djangoreactjsauth1/loginregreactjsmui1/src/images/images/assam.jpg";

import standardicon from "../../../images/images/standardicon.png";
import preminumicon from "../../../images/images/preminum.png";
import checkbox from "../../../images/images/checkbox.svg";
import uncheckbox from "../../../images/images/uncheckbox.svg";

import facebook from '../../../images/images/facebook.svg';
import instagram from '../../../images/images/instagram.svg';
import twitter from '../../../images/images/twitter.svg';
import youtube from '../../../images/images/youtube.svg';

import banner from '../../../images/banner033.png';

export const brands = [
  { iconSrc: brand1 ,url:"https://www.skyscanner.co.in/"},
  { iconSrc: brand2 ,url:"https://www.agoda.com/en-in/?ds=WaB1VLGc%2BvG6d2TP"},
  { iconSrc: brand3 ,url:"https://www.holidify.com/" },
  { iconSrc: brand4 ,url:"https://www.momondo.in/"},
  { iconSrc: brand5 ,url:"https://www.redbus.in/"},
];

export const navlinks = [
  { link: "Home", id: "home"},
  { link: "About", id: "about" },
  { link: "Explore", id: "explore" },
  { link: "Flight", id: "pricing" },
  { link: "Contact", id: "contact" },
];

export const hero = {
  "title": "Trip Planner with AeroOxy",
  "subtitle": "Your Next Journey, Optimized",
  "text": "Build, personalize, and optimize your itineraries with our free AI trip planner. Designed for vacations, workations, and everyday adventures.",
  "btn1": "Get Started",
  "btn2": "Get Demo",
  "img": dashboard,
};

export const memory = {
  "title": "Need a Vacation?",
  "subtitle": "Leave it to us",
  "text": "So come join us as we revolutionize the way people travel. Say goodbye to stress and hello to adventure with Ai-Powered Trip Planner!",
  "img": memoryimg,
  "experience": [
    { "number": "10K", "title": "places" },
    { "number": "400", "title": " Collaboration" },
    { "number": "30k+", "title": "Happy Customer" },
  ],
};

export const placesAPI = [
  { "placeImg": place1, "location": "Himachal Pradesh", "distance": "3.0 hour dirve",id:"Himachal_Pradesh" },
  { "placeImg": place2, "location": "Ladakh", "distance": "2.5 hour dirve", id:"Ladakh"},
  { "placeImg": place3, "location": "Rajasthan", "distance": "4.7 hour drive",id:"Rajasthan" },
  { "placeImg": place4, "location": "Goa", "distance": "4 hour drive",id:"Goa" },
  { "placeImg": place5, "location": "Kashmir", "distance": "4.9 hour drive",id:"Kashmir" },
  { "placeImg": place6, "location": "Shimla", "distance": "4.5 hour drive",id:"go" },
  { "placeImg": place7, "location": "Meghalaya", "distance": "5.5 hour drive",id:"Meghalaya" },
  { "placeImg": place8, "location": "Tamil Nadu", "distance": "5.1 hour drive",id:"go" },
  { "placeImg": place9, "location": "Uttarakhand", "distance": "5.1 hour drive",id:"go" },
  { "placeImg": place10, "location": "Assam", "distance": "5.1 hour drive" ,id:"Assam"},
];
export const placesAPIP = [
  { "placeImg": place1, "location": "Himachal Pradesh", "distance": "3.0 hour dirve",id:"Himachal_Pradesh" },
  { "placeImg": place2, "location": "Ladakh", "distance": "2.5 hour dirve", id:"Ladakh"},
  { "placeImg": place3, "location": "Rajasthan", "distance": "4.7 hour drive",id:"Rajasthan" },
  { "placeImg": place4, "location": "Goa", "distance": "4 hour drive",id:"Goa" },
  { "placeImg": place5, "location": "Kashmir", "distance": "4.9 hour drive",id:"Kashmir" },
  { "placeImg": place6, "location": "Shimla", "distance": "4.5 hour drive",id:"go" },
  { "placeImg": place7, "location": "Meghalaya", "distance": "5.5 hour drive",id:"Meghalaya" },
  { "placeImg": place8, "location": "Tamil Nadu", "distance": "5.1 hour drive",id:"go" },
  { "placeImg": place9, "location": "Uttarakhand", "distance": "5.1 hour drive",id:"go" },
  { "placeImg": place10, "location": "Assam", "distance": "5.1 hour drive" ,id:"Assam"},
];


export const pricingapi = {
  "title": "Choose The Plan That Suits You",
  "text": "Many attractive offers by becomming a premium member",
  "btn1": "Monthly",
  "btn2": "Yearly",
  "plans": [
    {
      "planicon": standardicon,
      "title": "Standard Membership",
      "text": "Suitable for all users",
      "plantype": "Free / Year",
      "plancontent": [
        { "iconbox": checkbox, "text": "Find Popular Destination" },
        { "iconbox": checkbox, "text": "Priority Booking Schedule" },
        { "iconbox": checkbox, "text": "Daily Destination News" },
        { "iconbox": uncheckbox, "text": "Invite Friends Feature" },
        { "iconbox": uncheckbox, "text": "Limited Travel Stats" },
        { "iconbox": uncheckbox, "text": "Invite Friends Feature" },
        { "iconbox": uncheckbox, "text": "No Ads & Tax" },
      ],
      "buttonText": "Get Free",
    },
    {
      "planicon": preminumicon,
      "title": "Preminum Membership",
      "text": "Suitable for enthusiast travelers",
      "plantype": "$60 / Year",
      "plancontent": [
        { "iconbox": checkbox, "text": "Find Popular Destination" },
        { "iconbox": checkbox, "text": "Priority Booking Schedule" },
        { "iconbox": checkbox, "text": "Daily Destination News" },
        { "iconbox": checkbox, "text": "Invite Friends Feature" },
        { "iconbox": checkbox, "text": "Advanced Travel Stats" },
        { "iconbox": checkbox, "text": "Invite Friends Feature" },
        { "iconbox": checkbox, "text": "No Ads & Tax" },
      ],
      "buttonText": "Get Started",
    },
  ],
};

export const bannerAPI = {
  "title": "The Greet Outdoors",
  "text": "Whislist Curated By Trip Aerooxy.",
  "imgSrc": banner,
  "btnText": "Explore More"
}

export const footerAPI = {
  "titles": [ {"title": "About"},{"title": "Company"},{"title": "Support"} ],
  "links": [
    [
      {"link": "About US"},
      {"link": "Features"},
      {"link": "News"},
      {"link": "Menu"},
    ],
    [
      {"link": "Why Trip Aerooxy"},
      {"link": "Partner with Us"},
      {"link": "FAQ"},
      {"link": "Blog"},
    ],
    [
      {"link": "Account"},
      {"link": "Support Center"},
      {"link": "Feedback"},
      {"link": "Contact Us"},
      {"link": "Accesbility"},
    ],
  ],
  "sociallinks": [
    {"icon": facebook},{"icon": instagram},{"icon": twitter},{"icon": youtube},
  ]
};
