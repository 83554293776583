import React from "react";
import assam from "../../images/images/Explore/assam.jpg";

export const Assam = () => {
  const containerStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",

    margin: "auto",
  };

  const imageStyle = {
    maxWidth: "100%",
    borderRadius: "5px",
    marginBottom: "20px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease",
    display: "inline-block",
  };

  const handleRedirect = () => {
    // Redirect logic goes here
    // Example: window.location.href = 'https://example.com';
  };

  return (
    <div style={containerStyle}>
      <img
        src={assam} // Replace with your image URL
        alt="Himachal Pradesh"
        style={imageStyle}
      />
      <p>
        Assam is a state in northeastern India known for its wildlife,
        archeological sites and tea plantations. In the west, Guwahati, Assam’s
        largest city, features silk bazaars and the hilltop Kamakhya Temple.
        Umananda Temple sits on Peacock Island in the Brahmaputra river. The
        state capital, Dispur, is a suburb of Guwahati.
      </p>
      <a
        href="https://assamtourism.gov.in/"
        style={linkStyle}
        onClick={handleRedirect}
      >
        Explore Assam
      </a>
    </div>
  );
};
